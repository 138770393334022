export default [
  {
    name: 'Sergey Ogorodnov',
    role: 'Chairman & Founder',
    about:
      'A co-founder of Voltaware and responsible for business development. Sergey has 20+ years working experience with focus on telecoms and was CEO of the largest provider of fibre optics and mobile connectivity solutions to major Russian operators, but has always had an entrepreneurial spirit. His hobbies include tennis, running, and chess.',
    img: {
      src: '/img/team/members/sergey.jpg',
      alt: 'sergey ogorodnov',
    },
  },
  {
    name: 'Rishabh Jaipuria',
    role: 'Chief Operating Officer',
    about:
      'Rishabh is passionate about everything mobile and digital. With over 15 years in the industry, he is now leading the product vision and marketing at Voltaware. He’s always got his eye on industry trends, making sure we continue to stay way ahead of the curve. When not working, he can be seen cooking and spending time with his two kids.',
    img: {
      src: '/img/team/members/rishabh.jpg',
      alt: 'rishabh jaipuria',
    },
  },
  {
    name: 'Gonçalo Moraes',
    role: 'Head of Development',
    about:
      'Gonçalo is responsible for our technology, specifically leading our app development. With over 20 years in technology startups he has plenty of experience to bring to the table.',
    img: {
      src: '/img/team/members/goncalo.jpg',
      alt: 'gonçalo moraes',
    },
  },
  {
    name: 'Dr. Antoine Clais',
    role: 'Head of Data Science',
    about:
      'Antoine holds a Ph.D in Mathematics and has 2 years of experience as a post-doctoral researcher. As a Data Scientist, he enjoys finding mathematical patterns in data. Outside of work he loves to read Marcel Proust and cook quiche Lorraine.',
    img: {
      src: '/img/team/members/antoine.jpg',
      alt: 'dr antoine clais',
    },
  },
  {
    name: 'Michal Durec',
    role: 'Firmware Engineer',
    about:
      'Michal has over 7 years experience in embedded software development, mainly in the fire detection industry. At Voltaware, his responsibilities include firmware development and testing, and reviewing hardware design. As well as his interests in machines and electronics, outside of work he might be found long distance cycling.',
    img: {
      src: '/img/team/members/michal.jpg',
      alt: 'michal durec',
    },
  },
  {
    name: 'Gian Paolo Alessi',
    role: 'Product Manager',
    about:
      'Gian develops design tools to help the company manage itself more efficiently and does QC on our products and features. He has a double major in Industrial and Computer Engineering. He is Mexican, French and Italian, and speaks four languages fluently. he loves climbing, hiking and photography, but he always has time to make Mexican breakfast.',
    img: {
      src: '/img/team/members/gian.jpg',
      alt: 'gian paolo alessi',
      link: 'https://www.linkedin.com/in/gian-paolo-alessi-a401b5114/',
    },
  },
  {
    name: 'Dinesh Kumar Deenadayalan',
    role: 'Product Executive',
    about:
      "Not much escapes Dinesh's keen eye for detail, if something's not right, he'll find it! With 3 years’ experience as a product manager he plays a central role in developing our app. In his spare time he loves to read and keep up to date with current world affairs.",
    img: {
      src: '/img/team/members/dinesh.jpg',
      alt: 'dinesh kumar deenadayalan',
    },
  },
  {
    name: 'Amir Alavi',
    role: 'Embedded System Designer',
    about:
      'Amir has B.Sc. and M.Sc. in Electrical Engineering. His role at Voltaware is firmware developer in which he has 5 years experience. Amir tries to employ new technologies for embedded software and hardware development from embedded Linux to different real-time operating systems and he is pursuing a PhD in electronic engineering. When not working, he can be seen playing computer games, seriously.',
    img: {
      src: '/img/team/members/amir.jpg',
      alt: 'amir alavi',
      link: 'https://www.linkedin.com/in/seyed-amir-alavi/',
    },
  },
  {
    name: 'Antonio Lago',
    role: 'Senior Software Engineer',
    about:
      'With 6 years of experience behind him, Antonio has a passion for coding and is a member of our app development team. When he is not coding he likes to utilise his software writing skills. He particularly enjoys reading about philosophy and understanding the world we live in.',
    img: {
      src: '/img/team/members/lake.jpg',
      alt: 'antonio lake',
    },
  },
  {
    name: 'William Lopes',
    role: 'Machine Learning Engineer',
    about:
      'With a Masters degree in Applied Computing and 10 years of experience in programming, notably in developing Computer Vision systems, William now plays an important role in advancing our machine learning algorithms. When not at work he loves to travel and read books about ancient history.',
    img: {
      src: '/img/team/members/william.jpg',
      alt: 'william lopes',
    },
  },
  {
    name: 'Elena Blair',
    role: 'HR Manager',
    about:
      'Elena holds an MCIPD qualification and has over 19 years of experience within blue chip multinational organisations. Committed to good practice in HR, she strives to provide pragmatic and balanced advice and has great awareness of Corporate Social Responsibility. She enjoys trips to the gym and taking her dog for walks.',
    img: {
      src: '/img/team/members/elena.jpg',
      alt: 'elena blair',
    },
  },
  {
    name: 'François Gruber-Magitot',
    role: 'Senior Business Analyst',
    about:
      'After having finished his Master of European politics at the London School of Economics, François decided to shift towards the energy sector. At Voltaware, he is responsible for Sales, Marketing and Business Development. He has lived in France, Germany, Spain and the UK, and is fluent in those four languages. Outside work, François loves Muay Thai, mountaineering and music.',
    img: {
      src: '/img/team/members/francois.jpg',
      alt: 'françois gruber-magitot',
    },
  },
  {
    name: 'John Berry',
    role: 'Finance Manager',
    about:
      'John is a chartered accountant and has an MBA. He has worked in the Marketing Services, Film Finance, Renewable Energy and Waste to Energy sectors. He runs slowly and skis badly.',
    img: { src: '/img/team/members/john.jpg', alt: 'john' },
  },
  {
    name: 'Maria Dancianu',
    role: 'Junior Data Scientist',
    about:
      'With a background in Economics and a recent MSc degree in Data Analytics, Maria joined the Voltaware team where she combined her interest for technology and data analysis with her passion for environmental conservation, contributing to make people more aware of their energy consumption. She loves spending her free time trekking, doing some yoga and reading.',
    img: { src: '/img/team/members/maria.jpg', alt: 'maria' },
  },
  {
    name: 'Giulia Crookham',
    role: 'Office Manager/Accounting Assistant',
    about:
      'Giulia has a BA in Management and Business Studies. She has worked for many multinationals in both the UK and abroad. She has recently joined Voltaware, where she works as Office Manager and Accounting Assistant. Giulia speaks fluent Italian, English and German.  She enjoys visiting exhibitions, practising her languages and jogging along the river.',
    img: { src: '/img/team/members/giulia.jpg', alt: 'giulia' },
  },
  {
    name: 'Pedro Pacheco',
    role: 'DevOps Engineer',
    about:
      'Working professional with more than 12 years of IT experience, Pedro is active in DevOps practices. Currently working as Cloud Infrastructure Engineer at Voltaware, Pedro is helping the company have the most security and reliable system. He loves to read and talk about Social Science and practice Yoga.',
    img: { src: '/img/team/members/pacheco.png', alt: 'pacheco' },
  },
  {
    name: 'Tiago Perini',
    role: 'DevOps Engineer',
    about:
      'Tiago has more than 10 years of experience as a sysadmin, managing especially Linux systems, and more than 5 years of experience with virtualized environments management. He holds a technology degree in computer networks and, at Voltaware, plays the role of cloud infrastructure engineer. He dedicates his free time to music or following scientific dissemination content in general.',
    img: { src: '/img/team/members/perini.png', alt: 'perini' },
  },
  {
    name: 'Dr. Daniela Uez',
    role: 'Quality Assurance Specialist',
    about:
      "Daniela has a BSc in Computer Science from University of Caxias do Sul, an MSc and a PhD in Automation and Systems Engineering from the Federal University of Santa Catarina working on agent oriented software engineering and multiagent systems. She has been working in software development since 2003. She loves learning new things so she reads a lot; fantasy and history, and she's passionate about movies.",
    img: { src: '/img/team/members/daniela.jpg', alt: 'daniela' },
  },
  {
    name: 'Pedro Ramalho',
    role: 'Front-end Developer',
    about:
      'After pursuing a background in Civil Engineering, Pedro has fully embraced the Software Development world. He loves to learn new things every day and being challenged. He mostly works with Front-end technologies. Believes the world is constantly changing and that we must adapt to it.',
    img: { src: '/img/team/members/ramalho.jpg', alt: 'ramalho' },
  },
  {
    name: 'Tatiana Pirondi',
    role: 'Web Designer',
    about:
      'Tati is a Web Designer over 10 years. She’s passionate about Design and had worked from social networks, site layouts, brand development to UI design. On her free time, she likes to read a lot and learn new things, music and books are her passions.',
    img: { src: '/img/team/members/tati.jpg', alt: 'tati' },
  },
  {
    name: 'Nifemi Bamgbose',
    role: 'Data Analyst',
    about:
      'Nifemi has a background in Electrical and Electronics Engineering. He is passionate about emerging technologies and their impact on the environment. He works as a data analyst ensuring the machine learning models are working accurately. In his free time, you’ll find him exploring new ideas or working on fashion.',
    img: { src: '/img/team/members/nifemi.jpg', alt: 'nifemi' },
  },
  {
    name: 'Dr. Furqan Tahir',
    role: 'Senior Data Science Consultant',
    about:
      'Furqan is a Chartered Engineer with a PhD in Control Engineering. He has over 7 years of experience in data driven modelling, advanced process monitoring, control and optimisation. As a Senior Data Scientist at Voltaware, he is responsible for improving the core algorithms as well as managing/delivering projects of key strategic value. In his free time, Furqan enjoys playing tennis, travelling and trekking.',
    img: { src: '/img/team/members/furqan.jpg', alt: 'furqan' },
  },
  {
    name: 'Zaffar H. Janjua',
    role: 'Senior Data Scientist Consultant For Smart Alerts',
    about:
      'Zaffar has a Ph.D. degree in computer science with majors in Artificial intelligence and IoT. He has 10 years of professional experience in academia and industry. His particular domain of work is behavioral monitoring and healthcare. As a senior data scientist in Voltaware, he is responsible for behavioral monitoring and smart alert products. Besides his professional activities, he loves traveling, playing piano, and reading books.',
    img: { src: '/img/team/members/zaffar.jpg', alt: 'zaffar' },
  },
  {
    name: 'Sonia Sisó',
    role: 'Business Analyst',
    about:
      'Sonia has an extensive background experience in Communication online and offline, holding a double Bachelor’s degree and a Master. Also, she has experience in developing new business opportunities within the tech sector. She is really passionate about challenging projects, especially if they contribute to creating a greener and more environmentally conscious world. "Only by sharing the knowledge and working together we will get huge changes for our own future and the one of the next generations".',
    img: { src: '/img/team/members/sonia.jpg', alt: 'sonia' },
  },
  {
    name: 'Rodrigo Lucchesi',
    role: 'Machine Learning Engineer',
    about:
      'Rodrigo is an Energy/Software Engineer interested in applying machine learning in energy systems, environment issues and the human mind. He is also an Open Source user and an enthusiast big wave surfer and martial arts practitioner.',
    img: { src: '/img/team/members/rodrigo.jpg', alt: 'rodrigo' },
  },
  {
    name: 'Gabriel Baggio',
    role: 'Scrum Master',
    about:
      'With a Bachelor Degree in Industrial Engineering, Gabriel is passionate about Agile methodology and Management, he has wide experience in both project and people management. His strive for personal development makes him believe that process improvements can be applied almost everywhere.',
    img: { src: '/img/team/members/gabriel.jpg', alt: 'gabriel' },
  },
];
