import React, { Component } from 'react';
import {Link} from 'react-router-dom';

import ShortBanner from '../components/short-banner.js';
import Meta from '../components/meta.js';

class IntegrateWithVoltaware extends Component {
  render() {
    return (
      <div id="integrate-with-voltaware" className="volta-page">
        <Meta
          title="Integrate With Voltaware Cloud Platform For Utilities"
          description="Voltaware Cloud platform integrates data from the Voltaware sensor of from smart meters through API."
          url="https://www.voltaware.com/integrate-with-voltaware"
        />

        <ShortBanner
          title="Integrate with Voltaware"
          image="integrate/banner.jpg"
        />

        <div className="catchphrase-section">
          <h2>
            Voltaware has built a data intelligence platform that allows utilities to seamlessly integrate the Voltaware services
            directly into their product offering.
          </h2>
        </div>

        <div className="text-section light-grey no-padding-bottom">
          <h1 id="voltaware-sensor">Data analytics platform</h1>
          <div className="single-column-wrapper full-width-lists">
            <p>
              The Voltaware platform works with either smart meters or the Voltaware sensor to build features into the utility’s customer-facing
              application. Through a combination of flexible APIs, you can choose to integrate selected features in your front-end services.
            </p>
            <p>
              Deeper integration is also possible, Voltaware services can be integrated directly into your back-end services. Voltaware services
              can be offered to your customers using data collected from either:
            </p>
            <ol>
              <li>Installing a Voltaware Sensor</li>
              <li>Your existing Smart Meters</li>
            </ol>
          </div>
        </div>

        <div className="text-section white">
          <div className="single-column-wrapper">
            <h1>Sensor-based platform</h1>
            <img src="/img/integrate/sensor-based-platform.svg" alt="Sensor-based platform" />
            <Link
              smooth
              to="/our-technology/voltaware-sensors"
              title="Installing the Sensor"
              className="btn-outlined"
            >
              Installing the Sensor
            </Link>
          </div>
        </div>

        <div className="text-section white">
          <div className="single-column-wrapper light-grey with-vertical-padding">
            <h2>Functionality for end-users</h2>
            <ul className="no-margin-bottom">
              <li>Android and iOS Mobile App</li>
              <li>Email Report</li>
            </ul>
            <div className="buttons-section small-padding-top">
              <a
                href="https://vimeo.com/310759173"
                target="_blank"
                rel="noopener noreferrer"
                alt="App Demo"
                className="btn-outlined"
              >
                App Demo
              </a>
            </div>

            <h2>Functionality for customer support</h2>
            <ul className="no-margin-bottom">
              <li>Access to our CRM dashboard:</li>
            </ul>
            <div className="buttons-section small-padding-top small-padding-bottom">
              <a
                href="https://crm.voltaware.com/"
                target="_blank"
                rel="noopener noreferrer"
                className="btn-outlined"
              >
                CRM Access
              </a>
            </div>
          </div>
        </div>

        <div className="text-section white">
          <div className="single-column-wrapper full-width-lists">
            <h2>Customization for third parties:</h2>
            <ul>
              <li>Customise the Voltaware mobile app with your company’s visual identity.</li>
              <li>Integrate existing systems with our Cloud Platform.</li>
            </ul>
            <p>
              Voltaware Cloud Platform offers an HTTP API and Data Queues for you to build your services on it.
            </p>
            <p>
              Voltaware's sensor constantly samples voltage and current signals of the mainline at high frequency. Every time an appliance
              is switched on or off, the sensor collects different data points related to that particular event and this is transmitted to
              the Cloud Platform.
            </p>
            <div className="buttons-section small-padding-top small-padding-bottom">
              <a
                href="https://docs.voltaware.com"
                alt="Voltaware Services"
                target="_blank"
                rel="noopener noreferrer"
                className="btn-outlined"
              >
                Voltaware Services
              </a>
            </div>
          </div>
        </div>

        <div className="text-section light-grey no-padding-bottom">
          <h1 id="voltaware-sensor">Integration services</h1>
          <div className="single-column-wrapper">
            <p>
              The Cloud Platform collects all the data coming from the sensor and processes the readings using Machine Learning techniques.
              Analytics are computed and made available to the APIs in real-time.
            </p>
            <h2 className="big-margin-top">
              HTTP API
            </h2>
            <p>
              It's a RESTFul API where you must query the API via an HTTP client library. It can be used to integrate our data into a
              client-facing mobile application or a web application, and you can even integrate it directly with your back-end services.
              It provides information about breakdown data for cost and consumption by period, allowing to set up an energy tariff and
              other account configurations.
            </p>
            <div className="buttons-section small-padding-top">
              <a
                href="https://docs.voltaware.com/services.html"
                alt="API Documentation"
                target="_blank"
                rel="noopener noreferrer"
                className="btn-outlined"
              >
                API Documentation
              </a>
            </div>

            <h2>
              Data queue
            </h2>
            <p>
              It's a push-based, publish/subscribe protocol. Once you subscribe to a queue, you will receive events from your designated
              sensors - you will get an event every time the sensor detects an ON or OFF on any appliance connected to the wire. It is
              suitable to develop back-end event-driven services requiring a larger degree of flexibility for what you can build additionally.
            </p>
            <div className="buttons-section small-padding-top">
              <a
                href=" https://docs.voltaware.com/sensor.html"
                alt="Back-end Services"
                target="_blank"
                rel="noopener noreferrer"
                className="btn-outlined"
              >
                Back-end Services
              </a>
            </div>
          </div>
        </div>

        <div className="text-section white no-padding-bottom">
          <div className="single-column-wrapper">
            <h1>Smart meter-based platform</h1>
            <img src="/img/integrate/smart-meter-based-platform.svg" alt="Smart meter-based platform" />
            <p>
              To integrate your smart meter data to the Voltaware Cloud Platform, you must first implement our Data Ingestion API.
            </p>
            <div className="buttons-section small-padding-top">
              <a
                href="https://docs.voltaware.com/smartmeter.html"
                alt="Smart Meter Integration"
                target="_blank"
                rel="noopener noreferrer"
                className="btn-outlined"
              >
                Smart Meter Integration
              </a>
            </div>
            <p>
              Once data ingestion is in place, these will be available:
            </p>
          </div>
        </div>

        <div className="text-section white">
          <div className="single-column-wrapper light-grey with-vertical-padding">
            <h2>
              Functionality for end-users
            </h2>
            <ul className="no-margin-bottom">
              <li>Email Report</li>
            </ul>
            <div className="buttons-section small-padding-top">
              <a
                href="https://vimeo.com/310759173"
                target="_blank"
                rel="noopener noreferrer"
                alt="App Demo"
                className="btn-outlined"
              >
                App Demo
              </a>
            </div>

            <h2>
              Functionality for customer support
            </h2>
            <ul className="no-margin-bottom">
              <li>Access to our CRM dashboard:</li>
            </ul>
            <div className="buttons-section small-padding-top small-padding-bottom">
              <a
                href="https://crm.voltaware.com/"
                target="_blank"
                rel="noopener noreferrer"
                className="btn-outlined"
              >
                CRM Access
              </a>
            </div>
          </div>
        </div>
    </div>
    );
  }
}

export default IntegrateWithVoltaware;
