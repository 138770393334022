import React from 'react';

// Components
import Member from '../components/member.js';
import Meta from '../components/meta.js';

import members from '../data/members';

const AboutUs = () => (
  <div id="about-us" className="volta-page">
    <Meta
      title="Meet the Voltaware team"
      description="The Voltaware team comprises a group of professionals from diverse industries who came together to challenge the lack of transparency in the energy sector."
      url="https://www.voltaware.com/about-us"
    />

    <section className="container about-us-team">
      <div className="about-us-header">
        <h1>Meet the team</h1>

        <p>
          The Voltaware team comprises a group of professionals from diverse
          industries who came together to challenge the lack of transparency
          in the energy sector. Drawing upon our collective expertise, we
          have developed a solution that enables consumers to become more
          energy-efficient without compromising their lifestyle.
        </p>
      </div>

      <div className="about-us-members">
        {members.map((member, i) => (
          <Member key={i} member={member} />
        ))}
      </div>
    </section>
  </div>
);

export default AboutUs;
